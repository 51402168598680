let switchFlag = false
// インターバル
let interval = 100
// 最後に実行した時間
let lastTime = new Date().getTime() - interval
// let target = document.getElementById('nav')
// let menuCheck = document.getElementById('nav-input')
let goTop = document.getElementById('topScroll');
let scroll = window.pageYOffset;
let before_scroll = 0;

// スクロールイベントPC版
const throttleFunc = () => {
  // 最後に実行した時間から間引きたい時間経過していたら実行
  if ( (lastTime + interval) <= new Date().getTime() ||  window.pageYOffset == 0 ) {
    scroll = window.pageYOffset
    lastTime = new Date().getTime();
    if (scroll > 200 && !switchFlag) {
      // target.classList.remove('nav_home')
      goTop.classList.remove('topScrollHide')
      switchFlag = true;
    } else if (scroll < 200 && switchFlag) {
      // target.classList.add('nav_home')
      goTop.classList.add('topScrollHide')
      switchFlag = false;
    }
  }
}

// モバイル用処理
const throttleFunc_mobile = (event) => {
  // メニューが開いている時は動かさない
    if ((lastTime + interval) <= new Date().getTime()) {
      scroll = event.changedTouches[0].screenY
      lastTime = new Date().getTime();
      if (before_scroll > scroll && switchFlag && window.pageYOffset > 100) {
        // target.classList.remove('nav_hidden')
        goTop.classList.remove('topScrollHide')
        switchFlag = false;
      } else if (window.pageYOffset < 100) {
        // target.classList.add('nav_hidden')
        goTop.classList.add('topScrollHide')
        switchFlag = true;
      }
    }
}
const getTouchPoint = (event) => {
  before_scroll = event.changedTouches[0].screenY
}



if (typeof window !== `undefined`) {
  if(navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)){
    //ここに書いた処理はスマホ閲覧時のみ有効となる
    // window.addEventListener("touchmove",throttleFunc_mobile, false)
    window.addEventListener("touchmove",throttleFunc_mobile, false)
    window.addEventListener("touchstart",getTouchPoint, false)
    
  }
  else {
    window.addEventListener('scroll', throttleFunc, false)
  }
}